import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Contact = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
      <div
          name="contact"
          className="w-full h-screen bg-gradient-to-l from-[#003049] to-[#d62828] text-gray-300 py-32"
      >
        <div className="flex flex-col justify-center items-center w-full h-full text-white" data-aos="fade-down-right" data-aos-duration="400">
          <p className="text-4xl font-bold inline border-b-4 border-[#8153d4]">
            Contact
          </p>
          <p className="py-6">
            Envoyez-moi un e-mail -{" "}
            <a href="mailto:francktwl@francktwl.fr" className="font-bold">francktwl@francktwl.fr</a>
          </p>
        </div>
      </div>
  );
};

export default Contact;
