import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {prog} from '../data/Static';

const Competence = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
      <div
          name="competence"
          className="w-full bg-gradient-to-l from-[#003049] to-[#d62828] text-gray-300  py-32"
      >
        <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center  h-full text-white">
          <div>
            <p className="text-4xl font-bold inline border-b-4 border-[#8153d4]">
              Compétences
            </p>
            <p className="py-6">
              J'ai utilisé et travaillé avec les technologies suivantes dans mes projets.
            </p>
          </div>

          <div className="w-full grid grid-cols-2 sm:grid-cols-4 gap-8 text-center">
            {prog.map(({ id, src, titre, style }) => (
                <div
                    key={id}
                    className={`shadow-md transform hover:scale-105 duration-500 py-2 rounded-lg ${style}`}
                    data-aos="zoom-out-up" data-aos-duration={id+'00'} data-aos-easing="linear"
                >
                  <img
                      src={src}
                      alt=""
                      className="mx-auto"
                      style={{ width: "4rem", height: "4rem" }}
                  />
                  <p className="mt-1">{titre}</p>
                </div>
            ))}
          </div>
        </div>
      </div>
  );
};

export default Competence;
