import React, { useEffect, useState } from "react";
import Logo from "../assets/logo.png";
import {FaTimes, FaGithub, FaInstagram, FaDiscord} from "react-icons/fa";
import { BsGridFill } from "react-icons/bs";
import { HiArrowSmUp, HiMail } from "react-icons/hi";
import { Link } from "react-scroll";

const BarreNavigation = () => {
  const [nav, setNav] = useState(false);
  const [backToTop, setBackToTop] = useState(false);
  const [showShadowNav, setShowShadowNav] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setBackToTop(true);
        setShowShadowNav(true);
      } else {
        setBackToTop(false);
        setShowShadowNav(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = () => {
    setNav(!nav);
    setBackToTop(false);
  };


  return (
      <div className={!showShadowNav ? 'fixed w-full h-[80px] flex justify-between items-center px-4 bg-gradient-to-l from-[#003049] to-[#d62828] text-gray-300 z-20':'fixed w-full h-[80px] flex justify-between items-center px-4 bg-gradient-to-l from-[#003049] to-[#d62828] text-gray-300 z-20 shadow-2xl'}>
        <div>
          <img src={Logo} alt="logo" style={{ width: "35px" }} />
        </div>

        <ul className="hidden md:flex">
          <li className="hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
            <Link to='accueil' smooth={true} duration={500}>Accueil</Link>
          </li>
          <li className="hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
            <Link to='apropos' smooth={true} duration={500}>À Propos</Link>
          </li>
          <li className="hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
            <Link to='competence' smooth={true} duration={500}>Compétences</Link>
          </li>
          <li className="hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
            <Link to='experience' smooth={true} duration={500}>Expérience</Link>
          </li>
          <li className="hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
            <Link to='travail' smooth={true} duration={500}>Travail</Link>
          </li>
          <li className="hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
            <Link to='contact' smooth={true} duration={500}>Contact</Link>
          </li>
        </ul>

        <div onClick={handleClick} className="md:hidden z-10">
          {!nav ? <BsGridFill /> : <FaTimes />}
        </div>

        <div className={
          !nav
              ? "hidden"
              : "absolute top-0 left-0 w-full h-screen bg-[#fcbf49] flex flex-col justify-center items-center transition ease-in-out duration-300 delay-700"
        }>
          <div>
            <img src={Logo} alt="logo" style={{ width: "45px" }} className="mb-7" />
          </div>
          <ul
              className='w-full  flex flex-col justify-center items-center'
          >
            <li className="py-6 text-4xl hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
              <Link onClick={handleClick} to='accueil' smooth={true} duration={500}>Accueil</Link>
            </li>
            <li className="py-6 text-4xl hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
              <Link onClick={handleClick} to='apropos' smooth={true} duration={500}>À Propos</Link>
            </li>
            <li className="py-6 text-4xl hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
              <Link onClick={handleClick} to='competence' smooth={true} duration={500}>Compétences</Link>
            </li>
            <li className="py-6 text-4xl hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
              <Link onClick={handleClick} to='experience' smooth={true} duration={500}>Expérience</Link>
            </li>
            <li className="py-6 text-4xl hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
              <Link onClick={handleClick} to='travail' smooth={true} duration={500}>Travail</Link>
            </li>
            <li className="py-6 text-4xl hover:text-[#8153d4] font-semibold hover:duration-200 active:text-[#8153d4]">
              <Link onClick={handleClick} to='contact' smooth={true} duration={500}>Contact</Link>
            </li>
          </ul>
          <div className="mt-10">
            <p>Droits d'auteur réservés © 2023</p>
          </div>
        </div>

        {backToTop && (
            <div className="fixed flex-col top-[90%] right-2 md:right-6 animate-bounce bg-white text-black rounded-full">
              <button className="p-3">
                <Link to='accueil' smooth={true} duration={500}><HiArrowSmUp size={20} /></Link>
              </button>
            </div>
        )}

        <div className="hidden lg:flex fixed flex-col top-[35%] left-0">
          <ul>
            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-slate-200 rounded-e-2xl font-semibold">
                <a
                    target="_blank"
                  href="https://www.instagram.com/francktwl_photo/"
                  className="flex justify-between items-center w-full text-black"
              >
                Instagram <FaInstagram size={30} />
              </a>
            </li>

            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-slate-200 rounded-e-2xl font-semibold">
              <a
                  target="_blank"
                  href="https://git.francktwl.fr/Franck"
                  className="flex justify-between items-center w-full text-black"
              >
                Github <FaGithub size={30} />
              </a>
            </li>

            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-slate-200 rounded-e-2xl font-semibold">
              <a
                  target="_blank"
                  href="https://discordapp.com/users/320512642785607681"
                  className="flex justify-between items-center w-full text-black"
              >
                Discord <FaDiscord size={30} />
              </a>
            </li>

            <li className="w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 bg-slate-200 rounded-e-2xl font-semibold">
              <a
                  target="_blank"
                  href="mailto:francktwl@francktwl.fr"
                  className="flex justify-between items-center w-full text-black"
              >
                Email <HiMail size={30} />
              </a>
            </li>
          </ul>
        </div>
      </div>
  );
};

export default BarreNavigation;
