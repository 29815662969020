import './App.css';
import APropos from './components/APropos';
import Contact from './components/Contact';
import Experience from './components/Experience';
import Accueil from './components/Accueil';
import BarreNavigation from './components/BarreNavigation';
import Competence from './components/Competence';
import Travail from './components/Travail';
import React from "react";
import AnimatedCursor from "react-animated-cursor"
function App() {
    return (
        <div className="App">
                <AnimatedCursor
                    innerSize={8}
                    outerSize={35}
                    innerScale={1}
                    outerScale={2}
                    outerAlpha={0}
                    hasBlendMode={true}
                    innerStyle={{
                        backgroundColor: 'var(--cursor-color)'
                    }}
                    outerStyle={{
                        border: '3px solid var(--cursor-color)'
                    }}
                    clickables={[
                            'a',
                            'input[type="text"]',
                            'input[type="email"]',
                            'input[type="number"]',
                            'input[type="submit"]',
                            'input[type="image"]',
                            'label[for]',
                            'select',
                            'textarea',
                            'button',
                            '.link'
                    ]}
                />
            <BarreNavigation />
            <Accueil />
            <APropos />
            <Competence />
            <Experience />
            <Travail />
            <Contact />
        </div>
    );
}

export default App;
