import React from 'react'
import {HiArrowNarrowRight} from 'react-icons/hi'
import {Link} from 'react-scroll';
import Typewriter from "typewriter-effect";
import {isMobile} from 'react-device-detect';
const Mobile = () => {
    if(isMobile) {
        return (
            <Typewriter
                options={{
                    loop: true,
                }}
                onInit={(typewriter) => {
                    typewriter
                        .typeString("Étudiant Info")
                        .pauseFor(1000)
                        .deleteAll()
                        .typeString("Dev Web")
                        .start();
                }}
            />
        )
    }
    return (
        <Typewriter
            options={{
                loop: true,
            }}
            onInit={(typewriter) => {
                typewriter
                    .typeString("Étudiant en informatique ")
                    .pauseFor(1000)
                    .deleteAll()
                    .typeString("Développeur Web ")
                    .start();
            }}
        />
    );
};
const Accueil = () => {
    return (
        <div name="accueil" className="w-full h-screen bg-gradient-to-l from-[#003049] to-[#d62828]">
            <div className="max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full">
                <p className="text-gray-50 text-lg">Salut, Je m'appelle</p>
                <h1 className="text-4xl sm:text-7xl font-bold text-[#8153d4]">
                    Franck Thiaw-Woaye--Lapierre
                </h1>
                <div>
                    <h2 className="text-2xl sm:text-4xl font-bold text-zinc-50 pt-2 flex">
                        <span className='mr-2'>Je suis</span>
                        <Mobile/>
                    </h2>
                </div>
                <p className="text-gray-300 py-4 leading-8">
                    Quelqu'un qui aime apprendre, mettre en place des serveurs et des sites web faire du DevOps sans pour autant avoir l'expérience pour se permettre de dire en être un !
                </p>
                <div>
                    <Link
                        to="travail"
                        smooth={true}
                        duration={1000}
                        className="text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[#8153d4] hover:border-[#8153d4] rounded-sm hover:text-[#3A1078] font-semibold w-fit"
                    >
                        Voir le travail{" "}
                        <span className="group-hover:translate-x-1 duration-300">
    {" "}
                            <HiArrowNarrowRight className="ml-4"/>
  </span>
                    </Link>

                </div>
            </div>
        </div>
    );
}

export default Accueil;
