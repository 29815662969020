import html from "../assets/prog/html.png";
import css from "../assets/prog/css.png";
import js from "../assets/prog/js.png";
import sass from "../assets/prog/sass.png";
import tailwindcss from "../assets/prog/tailwindcss.png";
import symfony from "../assets/prog/symfony.png";
import react from "../assets/prog/react.png";
import ruby from "../assets/prog/ruby.png";
import php from "../assets/prog/php.png";
import docker from "../assets/prog/docker.png";
import git from "../assets/prog/git.png";
import github from "../assets/prog/github.png";
import minecraft from "../assets/prog/minecraft.png";
import python from "../assets/prog/python.png";
import nextjs from "../assets/prog/nextjs.png";
import nodejs from "../assets/prog/nodejs.png";
import webexhaust from "../assets/work/exhaust.png";
import webrixguard from "../assets/work/rixguard.png";
import webminetrax from "../assets/work/minetrax.png";

export const projets = [
  {
    titre: "Rixguard Website VPN",
    urlImage: webrixguard,
    technologies: ["Tailwindcss", "Laravel", "Flowbite"],
    urlProjet:
      "https://rixguard.re",
    duree: "500",
  },
  {
    titre: "Minetrax",
    urlImage: webminetrax,
    technologies: ["Tailwindcss", "Laravel", "Flowbite", "Minecraft", "Docker", "Java"],
    urlProjet: "https://minetrax.github.io/",
    duree: "600",
  },
  {
    titre: "Exhaust Esport Website",
    urlImage: webexhaust,
    technologies: ["Tailwindcss", "Laravel", "Flowbite"],
    urlProjet:
        "https://exhaust.re",
    duree: "700",
  },
];

export const experiences = [
  {
    date: "Août 2023",
    company: "Étudiant",
    position: "Étudiant",
    description:
      "Je suis étudiant à l'université de la réunion en l1 visant la l3 et toujours développeur pour la Exhaust Esport.",
  },
  {
    date: "Juin 2022",
    company: "Exhaust",
    position: "Devellopeur Web",
    description:
      "Je suis embauché par la Exhaust pour être leurs développeurs web et gérer leurs sites web et leurs serveurs comme leur serveur Minecraft et leur serveur VPN.",
  },
  {
    date: "Août 2020 - Septembre 2020",
    company: "Datatops",
    position: "DevOps Stage",
    description:
      "J'ai été accepté en stage dans un des datacenter de l'île se trouvant au tampon ou j'ai pu apprendre les bases du DevOps et de la gestion de serveurs. Et j'ai fait l'acquisition de mon premier serveur dédié grâce à eux !",
  },
];


// Array of langue objects
export const prog = [
  {
    id: 1,
    src: html,
    titre: "Html",
    style: "shadow-violet-500",
  },
  {
    id: 2,
    src: css,
    titre: "Css",
    style: "shadow-violet-500",
  },
  {
    id: 3,
    src: js,
    titre: "JavaScript",
    style: "shadow-violet-500",
  },
  {
    id: 4,
    src: sass,
    titre: "Sass",
    style: "shadow-violet-500",
  },
  {
    id: 5,
    src: tailwindcss,
    titre: "TailwindCss",
    style: "shadow-violet-500",
  },
  {
    id: 6,
    src: symfony,
    titre: "Symfony",
    style: "shadow-violet-500",
  },
  {
    id: 7,
    src: react,
    titre: "React",
    style: "shadow-violet-500",
  },
  {
    id: 8,
    src: ruby,
    titre: "Ruby",
    style: "shadow-violet-500",
  },
  {
    id: 9,
    src: php,
    titre: "Php",
    style: "shadow-violet-500",
  },
  {
    id: 10,
    src: nextjs,
    titre: "Next JS",
    style: "shadow-green-600",
  },
  {
    id: 11,
    src: nodejs,
    titre: "Node JS",
    style: "shadow-green-600",
  },
  {
    id: 12,
    src: github,
    titre: "Github",
    style: "shadow-slate-400",
  },
  {
    id: 13,
    src: git,
    titre: "Git",
    style: "shadow-slate-400",
  },

  {
    id: 14,
    src: docker,
    titre: "Docker",
    style: "shadow-blue-400",
  },
  {
    id: 15,
    src: python,
    titre: "Python",
    style: "shadow-yellow-400",
  },
  {
    id: 16,
    src: minecraft,
    titre: "Minecraft",
    style: "shadow-green-500",
  },
];
