import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { projets } from "../data/Static";

const Travail = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <div name="travail" className="w-full bg-gradient-to-l from-[#003049] to-[#d62828] py-32">
            <div className="max-w-screen-lg mx-auto p-4 flex flex-col justify-center  h-full text-white">
                <div>
                    <h1 className="text-4xl font-bold inline border-b-4 border-[#8153d4]">Projets Récents</h1>
                </div>
                <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4 mt-7">
                    {projets.map((projet) => (
                        <a
                            href={projet.urlProjet}
                            target="_blank"
                            rel="noreferrer"
                            className="bg-slate-200 rounded-lg overflow-hidden hover:translate-x-1 hover:duration-200"
                            key={projet.titre}
                            data-aos="fade-up" data-aos-duration={projet.duree} data-aos-easing="linear"
                        >
                            <img
                                src={projet.urlImage}
                                alt="travail"
                                className="w-full h-36 md:h-48 object-cover"
                            />
                            <div className="w-full p-5 text-black">
                                <h3 className="text-lg md:text-xl mb-2 md:mb-3 font-semibold">
                                    {projet.titre}
                                </h3>
                                <p className="flex flex-wrap gap-2 flex-row items-center justify-start text-xs md:text-sm ">
                                    {projet.technologies.map((item) => (
                                        <span
                                            key={item}
                                            className="inline-block px-2 py-1 bg-slate-300 rounded-md"
                                        >
                      {item}
                    </span>
                                    ))}
                                </p>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Travail;
